import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CustomerprofileService {

  private messageSourceCP = new BehaviorSubject("B1mlB8BPI9fFH32dUIGP"); //defaultmsg is 'default message'
  currentMessageCP = this.messageSourceCP.asObservable();

  constructor() { }

  changeMessageCP(messageCP: string) {
    this.messageSourceCP.next(messageCP)
  }

}