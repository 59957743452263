import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSortable} from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import 'rxjs';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../ledgerdetails/format-datepicker';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-ledgerfilter',
  templateUrl: './ledgerfilter.component.html',
  styleUrls: ['./ledgerfilter.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class LedgerfilterComponent implements OnInit {

  panelOpenState = true;

  public fmForm: FormGroup;

  //displayedColumns = ['date', 'name', 'debit', 'credit', 'description', 'edit', 'delete'];
  displayedColumns = ['date2', 'particulartype', 'name', 'book', 'debit', 'credit', 'tag', 'description'];
  //displayedColumns = ['date2', 'name', 'debit', 'credit', 'description'];
  dataSource: MatTableDataSource<any>; 

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ledkeyid: any;
  leddate: any;
  ledcust: any;
  leddebit: any;
  ledcredit: any;
  leddescription: any;
  bktag:any;
  ///////filter
  namesearch: any;
  selection: any;
  datesearch: any;
  filterValue = {
    name: '',
    date2s: '',
    date2e: '',
  };
  tagscollection: any;
  name: any;

  constructor(private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, public datePipe: DatePipe) {   }

  
  ngOnInit() {

    this.afs.collection<any>('Ledger').valueChanges().subscribe(data => { //filtered by book
      this.dataSource = new MatTableDataSource(data); 
      this.dataSource.filterPredicate = this.createFilter();
      console.log("FilteresData:" +JSON.stringify(this.dataSource.filteredData));
      this.dataSource.sort = this.sort;

      //sorting for date column
      this.dataSource.sortingDataAccessor = (item, property) => {
      //console.log(item)
        switch (property) {
          case 'date2': {
            return (moment(item.date2, "DD-MM-YYYY").toDate());
          }
          default: return item[property];
        }
      };

      this.dataSource.paginator=this.paginator;



    })

    
    this.sort.sort(<MatSortable>{
      id: 'date2',
      start: 'desc',
    }
  );

}

getTotalDebit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tdebit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseInt(a.debit);
});
return tdebit;
}
}


getTotalCredit(){
  if(this.dataSource === undefined){return 0;}
  else if(this.dataSource != undefined){let tcredit = _.sumBy(this.dataSource.filteredData, function (a) {
     return parseInt(a.credit);
});
return tcredit;
}
}

  applyFilter1(filterValue: string) {
    this.filterValue.name = filterValue.trim(); 
    //filterValue = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(this.filterValue);  
    console.log(this.dataSource.filter);
    this.getTotalDebit();
    this.getTotalCredit();
  };
  applyFilter2(filterValue: string) {
    this.filterValue.date2s = this.datePipe.transform(new Date(this.datesearch.start),"dd-MM-yyyy"); 
    this.filterValue.date2e = this.datePipe.transform(new Date(this.datesearch.end),"dd-MM-yyyy"); 
    this.dataSource.filter = JSON.stringify(this.filterValue);   
    console.log("S: "+this.filterValue.date2s);
    console.log("E: "+this.filterValue.date2e);
    this.getTotalDebit();
  };



  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log("CURRDate:" +moment(data.date2, "DD-MM-YYYY").toDate());
      console.log("SDMoment:" +moment(searchTerms.date2s, "DD-MM-YYYY").toDate());
      console.log("EDMoment:" +moment(searchTerms.date2e, "DD-MM-YYYY").toDate());
      if((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
      && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
      {
        console.log(true);
      }
      else{
        console.log(false);
      }
      return (data.name.indexOf(searchTerms.name) !== -1)
        && ((moment(data.date2, "DD-MM-YYYY").toDate()) >= (moment(searchTerms.date2s, "DD-MM-YYYY").toDate())
        && (moment(data.date2, "DD-MM-YYYY").toDate()) <= (moment(searchTerms.date2e, "DD-MM-YYYY").toDate()))
    }
    return filterFunction;
  }

  trackByUid(index, item) {
    return item.uid;
  }


}