import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productcommondetails',
  templateUrl: './productcommondetails.component.html',
  styleUrls: ['./productcommondetails.component.css']
})
export class ProductcommondetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
