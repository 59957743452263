import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, of } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase';
import * as _ from 'lodash';

@Component({
  selector: 'app-productbuild',
  templateUrl: './productbuild.component.html',
  styleUrls: ['./productbuild.component.css']
})
export class ProductbuildComponent implements OnInit {

  public pcForm: FormGroup;
  prdkeyid: any;
  prdbrand: any;
  prdcategory: any;
  prdcollection: any;
  prcollection: any;
  filetot: number;
  prdcolor: any;
  prdsize: any;
  purrate: any;
  prdrate: any;
  prdcode: any;
  prdestrate: any;
  prdestrate2: any;
  prdqntyshop1: number;
  prdqntygodown: number;
  prdqntyshop2: number;
  favoriteTag: string;
  tags: string[] = ['Trending', 'Popular', 'New', 'None'];

  enableUpdate: boolean = false;
  
  prdhsncode: any;
  prdgst: any;
  prdigst: any;
  prdunit1: any=1;
  prdunit2: any="PC(S)";

  discinstitute: any;
  discprime: any;
  discregular: any;
  discwholesale: any;
  total: any;
 
  modno: any;
  lblcode: any;
  sizechartname: any;
  sizchrtname: any;

  productgstdisabled: boolean;
  productigstdisabled: boolean;
  productratedisabled: boolean;
  purchaseratedisabled: boolean;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  brndcollection: any;
  ctrycollection: any;
 

  ngOnInit() {
    
    this.filetot= 0;

    this.Form();  

    this.favoriteTag=this.tags[3]; // set default radio button checked

    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    // this.prcollection = this.afs.collection('Productbase').snapshotChanges()
    // .pipe(
    //   map(actions => actions.map(a => a.payload.doc.data()))
    // );

  }

  
  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productsizechartname: [''],
      productcode: [''],
      productcolor: [''],
      productimages: [''],
      purchaserate: [''],
      productrate: [''],
      productestimaterate: [''],
      productestimaterate2: [''],
      productsize: [''],
      productqntygodown: [''],
      productqntyshop1: [''],
      productqntyshop2: [''],

      productmodelno: [{value:''}],
      productlabelcode: [{value:''}],
      producthsncode: [{value:''}],
      productgst: [{value:''}],
      productigst: [{value:''}],
      productunit1: [{value:''}],
      productunit2: [{value:''}],
      discountinstitute: [{value:''}],
      discountprime: [{value:''}],
      discountregular: [{value:''}],
      discountwholesale: [{value:''}],

      // producthsncode: [{value:'',disabled: true}],
      // productgst: [{value:'',disabled: true}],
      // productigst: [{value:'',disabled: true}],
      // productunit1: [{value:'',disabled: true}],
      // productunit2: [{value:'',disabled: true}],
      // discountinstitute: [{value:'',disabled: true}],
      // discountprime: [{value:'',disabled: true}],
      // discountregular: [{value:'',disabled: true}],
      // discountwholesale: [{value:'',disabled: true}],
    })
  }

  // Accessing form control using getters
  get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productsizechartname() {
    return this.pcForm.get('productsizechartname');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }
  get productcolor() {
    return this.pcForm.get('productcolor');
  }
  get productimages() {
    return this.pcForm.get('productimages');
  }
  get productestimaterate() {
    return this.pcForm.get('productestimaterate');
  }
  get productestimaterate2() {
    return this.pcForm.get('productestimaterate2');
  }
  get productqntygodown() {
    return this.pcForm.get('productqntygodown');
  }
  get productqntyshop1() {
    return this.pcForm.get('productqntyshop1');
  }
  get productqntyshop2() {
    return this.pcForm.get('productqntyshop2');
  }

get productmodelno() {
  return this.pcForm.get('productmodelno');
}

get productlabelcode() {
  return this.pcForm.get('productlabelcode');
}

get producthsncode() {
  return this.pcForm.get('producthsncode');
}

get productgst() {
  return this.pcForm.get('productgst');
}

get productigst() {
  return this.pcForm.get('productigst');
}

get productunit1() {
  return this.pcForm.get('productunit1');
}

get productunit2() {
  return this.pcForm.get('productunit2');
}

get discountinstitute(){
  return this.pcForm.get('discountinstitute');
}

get discountprime(){
  return this.pcForm.get('discountprime');
}

get discountregular(){
  return this.pcForm.get('discountregular');
}

get discountwholesale(){
  return this.pcForm.get('discountwholesale');
}

  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.prdsize='';
  this.sizchrtname ='';
  this.prdcategory ='';
  this.resetproductdetails();
  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct,br){
    console.log(this.prdbrand);

    ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    //ct=this.prdcategory.substr(0,this.prdcategory.indexOf(' '));
    console.log(ct);

    
    this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
      if(doc.data().categories){
        for(let i=0;i<doc.data().categories.length;i++){
          if(doc.data().categories[i].categoryname == ct){
            this.sizchrtname = doc.data().categories[i].sizechart
            console.log(this.sizchrtname)
          }
          if(doc.data().categories[i].categoryname != ct){
          }
      }
      
    }
  })

    this.prcollection = this.afs.collection('Productbuild', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

//     this.afs.collection('Productbase', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , ct)).get().toPromise().then(querySnapshot => {
//       if (querySnapshot.size > 0) {
//         querySnapshot.forEach(doc => {
//           this.prdhsncode=doc.data().hsncode;
//           this.prdgst=doc.data().gst;
//           this.prdigst=doc.data().igst;
//           this.prdunit1=doc.data().unit1;
//           this.prdunit2=doc.data().unit2;
//           this.discinstitute=doc.data().discount.institute;
//           this.discprime=doc.data().discount.prime;
//           this.discregular=doc.data().discount.regular;
//           this.discwholesale=doc.data().discount.regular;
//           })
//       }
//       else{
//         this.prdhsncode='';
//         this.prdgst=0;
//         this.prdigst=0;
//         this.prdunit1=0;
//         this.prdunit2='';
//         this.discinstitute=0;
//         this.discprime=0;
//         this.discregular=0;
//         this.discwholesale=0;
//       }
// })
this.resetproductdetails();
  }

// Reset form's values
ResetForm() {
  this.urls = [];
  this.filetot= 0;
  this.pcForm.reset();

  this.productgstdisabled=false;
  this.productigstdisabled=false;
  this.productratedisabled=false;
  this.purchaseratedisabled=false;
}


submitProductbuildData() {
  this.add(this.pcForm.value.productid);
  //this.ResetForm();  // Reset form when clicked on reset button
  this.resetproductdetails();
};


add(id) {
   let ids =this.afs.createId();
  this.afs.collection("Products").doc(ids).set({

    0:"0",
    KEYID:ids,
    brand:this.prdbrand,
    category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      images:this.urls, //images
      size:this.prdsize,
      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      prate:isNaN(Number(this.purrate)) ? 0 : Number(this.purrate),
      rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
      estrate:isNaN(Number(this.prdestrate)) ? 0 : Number(this.prdestrate),
      estrate2:isNaN(Number(this.prdestrate2)) ? 0 : Number(this.prdestrate2),
      qntyshop1:isNaN(Number(this.prdqntyshop1)) ? 0 : Number(this.prdqntyshop1),
      qntyshop2:isNaN(Number(this.prdqntyshop2)) ? 0 : Number(this.prdqntyshop2),
      qntygodown:isNaN(Number(this.prdqntygodown)) ? 0 : Number(this.prdqntygodown),
      tag:this.favoriteTag,

      // modelno: this.modno.toUpperCase(),
      // labelcode: this.lblcode.toUpperCase(),

      modelno: (this.modno!=null) ? (this.modno.toUpperCase()):'',
      labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',

      hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
      gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
      igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,
      discount:{
        institute:isNaN(Number(this.discinstitute)) ? 0 : Number(this.discinstitute),
        prime:isNaN(Number(this.discprime)) ? 0 : Number(this.discprime),
        regular:isNaN(Number(this.discregular)) ? 0 : Number(this.discregular),
        wholesale:isNaN(Number(this.discwholesale)) ? 0 : Number(this.discwholesale),
      },
      
      //newly added
      sizechart: String(this.sizchrtname).substring(0,2),
      //newly added

  }, { merge: true })

    .catch((err) => {
      console.log(err);
    })


    this.afs.collection("Barcodebasket").doc(this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize).set({
      category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
      bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      size:this.prdsize,

      //newly added
      sizechart: this.sizchrtname,
      labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
      //newly added

        gst: this.prdgst,
        discount:{
        regular: Number(this.discregular),
      },


      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      rate:Number(this.prdrate),

      //qntycount:Number(Number(this.prdqntyshop1)),
      qntycount:Number(Number(this.prdqntyshop1)+Number(this.prdqntyshop2)+Number(this.prdqntygodown))
    })
   

    //---------------------tagbasket-----------------
    // if(this.favoriteTag!='None'){
    //   this.afs.collection("Tagbasket").doc(ids).set({
    //     brand:this.prdbrand,
    //     category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    //     bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
    //     code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    //     tag:this.favoriteTag,
    //   })
    // }
    //---------------------tagbasket-----------------

  //this.update(id);
}

clearbarcodebasket(){
  var deletearray=[];
  this.afs.collection('Barcodebasket').get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        deletearray.push(doc.data().code);
      })
    }
  }).then(()=>{
    for(let i=0; i<deletearray.length; i++){
      this.afs.collection('Barcodebasket').doc(String(deletearray[i])).delete();
    }
  });
 }


 resetproductdetails(){
  this.prdcolor='';
  this.prdsize='';
  this.modno='';
  this.prdhsncode='';
  this.lblcode='';
  this.prdgst='';
  this.prdigst='';
  this.purrate='';
  this.prdrate='';
  this.prdestrate='';
  this.prdestrate2='';
  this.prdqntyshop1=null;
  this.prdqntyshop2=null;
  this.prdqntygodown=null;
  this.discregular='';
  this.discprime='';
  this.discinstitute='';
  this.discwholesale='';
  this.favoriteTag=this.tags[3];
  this.urls = [];
  this.filetot= 0;

  this.enableUpdate=false;

  
  this.productgstdisabled=false;
  this.productigstdisabled=false;
  this.productratedisabled=false;
  this.purchaseratedisabled=false;

}

update(pid) {
  console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).update({
    images:this.urls, //images
    size:this.prdsize,
    color:this.prdcolor,
    //code:this.prdcolor+this.prdsize,
    code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    prate:isNaN(Number(this.purrate)) ? 0 : Number(this.purrate),
    rate:isNaN(Number(this.prdrate)) ? 0 : Number(this.prdrate),
    estrate:isNaN(Number(this.prdestrate)) ? 0 : Number(this.prdestrate),
    estrate2:isNaN(Number(this.prdestrate2)) ? 0 : Number(this.prdestrate2),
    qntyshop1:isNaN(Number(this.prdqntyshop1)) ? 0 : Number(this.prdqntyshop1),
    qntyshop2:isNaN(Number(this.prdqntyshop2)) ? 0 : Number(this.prdqntyshop2),
    qntygodown:isNaN(Number(this.prdqntygodown)) ? 0 : Number(this.prdqntygodown),
    tag:this.favoriteTag,

    // modelno:this.modno.toUpperCase(),
    // labelcode:this.lblcode.toUpperCase(),
    
    modelno: (this.modno!=null) ? (this.modno.toUpperCase()):'',
    labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',

    hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
    gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
    igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
    unit1:Number(this.prdunit1),
    unit2:this.prdunit2,
    discount:{
      "institute":isNaN(Number(this.discinstitute)) ? 0 : Number(this.discinstitute),
      "prime":isNaN(Number(this.discprime)) ? 0 : Number(this.discprime),
      "regular":isNaN(Number(this.discregular)) ? 0 : Number(this.discregular),
      "wholesale":isNaN(Number(this.discwholesale)) ? 0 : Number(this.discwholesale),
    },
  }).then(()=>{
    //this.ResetForm();
    this.resetproductdetails();
  })

  this.afs.collection("Barcodebasket").doc(this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize).set({
    category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
    size:this.prdsize,

    //newly added
    sizechart: this.sizchrtname,
    labelcode: (this.lblcode!=null) ? (this.lblcode.toUpperCase()):'',
    //newly added

      gst: this.prdgst,
      discount:{
      regular: Number(this.discregular),
    },


    color:this.prdcolor,
    // code:this.prdcolor+this.prdsize,
    code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    rate:Number(this.prdrate),

    //qntycount:Number(Number(this.prdqntyshop1)),
    qntycount:Number(Number(this.prdqntyshop1)+Number(this.prdqntyshop2)+Number(this.prdqntygodown))
  })

       //---------------------tagbasket-----------------
//   .then(()=>{
//     if(this.favoriteTag!='None'){
//     this.afs.collection("Tagbasket", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).set({
//       brand:this.prdbrand,
//       category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
//       bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
//       code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
//       tag:this.favoriteTag,
//     }, { merge: true })
//   }
//   if(this.favoriteTag=='None'){
//     this.afs.collection("Tagbasket", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).delete();
//   }
// })
    //---------------------tagbasket-----------------

}


read(pd,i) {  
  this.prdkeyid = pd.KEYID;
  this.prdbrand = pd.brand;
  this.prdcategory = pd.bcc+' '+pd.category;
  this.urls = pd.images;
  this.prdcode = pd.code;
  this.prdsize = pd.size;
  this.prdcolor = pd.color;
  this.purrate = pd.prate;
  this.prdrate = pd.rate;
  this.prdestrate = pd.estrate;
  this.prdestrate2 = pd.estrate2;
  this.prdqntyshop1 = pd.qntyshop1;
  this.prdqntyshop2 = pd.qntyshop2;
  this.prdqntygodown = pd.qntygodown;
  this.favoriteTag=pd.tag;

  this.modno=pd.modelno;
  this.lblcode=pd.labelcode;
  this.prdhsncode=pd.hsncode;
  this.prdgst=pd.gst;
  this.prdigst=pd.igst;
  this.prdunit1=pd.unit1;
  this.prdunit2=pd.unit2;
  this.discprime=pd.discount["prime"];
  this.discregular=pd.discount["regular"];
  this.discinstitute=pd.discount["institute"];
  this.discwholesale=pd.discount["wholesale"];

  this.filetot = this.urls.length;

  this.productgstdisabled=true;
  this.productigstdisabled=true;
  this.productratedisabled=true;
  this.purchaseratedisabled=true;
}

delete(pd,i) {
  this.prdkeyid = pd.KEYID;
  console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).doc(this.prdkeyid).delete();
}

checkprd(){
  console.log("hello");
  
  
  this.modno='';
  this.prdhsncode='';
  this.lblcode='';
  this.prdgst='';
  this.prdigst='';
  this.purrate='';
  this.prdrate='';
  this.prdestrate='';
  this.prdestrate2='';
  this.prdqntyshop1=null;
  this.prdqntyshop2=null;
  this.prdqntygodown=null;
  this.discregular='';
  this.discprime='';
  this.discinstitute='';
  this.discwholesale='';
  this.favoriteTag=this.tags[3];
  this.urls = [];
  this.filetot= 0;

  this.enableUpdate=false;

  if(this.prdbrand && this.prdcategory && this.prdcolor && this.prdsize){
    let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    this.afs.collection('Products', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , ct)
    .where('color', '==' , this.prdcolor).where('size', '==' , this.prdsize)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {

        this.enableUpdate=true;

        console.log("hello3");
        querySnapshot.forEach(doc => {
          this.prdkeyid = doc.data().KEYID;
          this.prdbrand = doc.data().brand;
          this.prdcategory = doc.data().bcc+' '+doc.data().category;
          this.urls = doc.data().images;
          this.prdcode = doc.data().code;
          this.prdsize = doc.data().size;
          this.prdcolor = doc.data().color;
          this.purrate = doc.data().prate;
          this.prdrate = doc.data().rate;
          this.prdestrate = doc.data().estrate;
          this.prdestrate2 = doc.data().estrate2;
          this.prdqntyshop1 = doc.data().qntyshop1;
          this.prdqntyshop2 = doc.data().qntyshop2;
          this.prdqntygodown = doc.data().qntygodown;
          this.favoriteTag=doc.data().tag;
        
          this.modno=doc.data().modelno;
          this.lblcode=doc.data().labelcode;
          this.prdhsncode=doc.data().hsncode;
          this.prdgst=doc.data().gst;
          this.prdigst=doc.data().igst;
          this.prdunit1=doc.data().unit1;
          this.prdunit2=doc.data().unit2;
          this.discprime=doc.data().discount["prime"];
          this.discregular=doc.data().discount["regular"];
          this.discinstitute=doc.data().discount["institute"];
          this.discwholesale=doc.data().discount["wholesale"];

          this.filetot = this.urls.length;
        
          
  this.productgstdisabled=true;
  this.productigstdisabled=true;
  this.productratedisabled=true;
  this.purchaseratedisabled=true;

        })
      }
    })
}
}

urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}


}