import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-productcrud',
  templateUrl: './productcrud.component.html',
  styleUrls: ['./productcrud.component.css']
})
export class ProductcrudComponent implements OnInit {

  public pcForm: FormGroup;

  qntyForm: FormGroup;
  rows: FormArray;
  itemForm: FormGroup;


  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ this.qntyForm = this.fb.group({
    items: [null, Validators.required],
    items_value: ['no', Validators.required]
  });

  this.rows = this.fb.array([]);
}

  brndcollection: any;
  ctrycollection: any;
  prcollection: any;
  filetot: number;

  prdkeyid: any;
  prdbrand: any;
  prdcategory: any;
  prdhsncode: any;
  prdgst: any;
  prdigst: any;
  prdunit1: any;
  prdunit2: any;

  discinstitute: any;
  discprime: any;
  discregular: any;
  discwholesale: any;

  ngOnInit() {

    this.Form();  

    this.qntyForm.get("items").valueChanges.subscribe(val => {
      if (val === true) {
        this.qntyForm.get("items_value").setValue("yes");
  
        this.qntyForm.addControl('rows', this.rows);
      }
      if (val === false) {
        this.qntyForm.get("items_value").setValue("no");
        this.qntyForm.removeControl('rows');
      }
    });
    
    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );


//temp
  // this.prcollection = this.afs.collection('Products', ref => ref.where('category', "==" , 'BADMINTON SHOE')).snapshotChanges()
  // .pipe(
  //   map(actions => actions.map(a => a.payload.doc.data()))
  // );
//temp

  }

  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      producthsncode: [''],
      productgst: [''],
      productigst: [''],
      productunit1: [''],
      productunit2: [''],
      discountinstitute: [''],
      discountprime: [''],
      discountregular: [''],
      discountwholesale: [''],
    })
  }

  // Accessing form control using getters
  get productbrand() {
    return this.pcForm.get('productbrand');
  }

  get productcategory() {
    return this.pcForm.get('productcategory');
  }

  get producthsncode() {
    return this.pcForm.get('producthsncode');
  }

  get productgst() {
    return this.pcForm.get('productgst');
  }

  get productigst() {
    return this.pcForm.get('productigst');
  }

  get productunit1() {
    return this.pcForm.get('productunit1');
  }

  get productunit2() {
    return this.pcForm.get('productunit2');
  }

  get discountinstitute(){
    return this.pcForm.get('discountinstitute');
  }

  get discountprime(){
    return this.pcForm.get('discountprime');
  }

  get discountregular(){
    return this.pcForm.get('discountregular');
  }

  get discountwholesale(){
    return this.pcForm.get('discountwholesale');
  }
  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct){
    this.prcollection = this.afs.collection('Products', ref => ref.where('category', "==" , ct)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
    }


  // Reset form's values
  ResetForm() {
    this.pcForm.reset();
  }
  

  submitProductData() {
    this.add(this.pcForm.value.productid);
    this.ResetForm();  // Reset form when clicked on reset button
  };


  add(id) {
    let ids =this.afs.createId();
    this.afs.collection("Products").doc(ids).set({
      KEYID: ids,
      brand: this.prdbrand,
      category: this.prdcategory,
      hsncode: this.prdhsncode,
      gst: this.prdgst,
      igst: this.prdigst,
      unit1: this.prdunit1,
      unit2: this.prdunit2,

      discount:{
        institute: this.discinstitute,
        prime: this.discprime,
        regular: this.discregular,
        wholesale: this.discwholesale,
      },
      qnty: [{
        images:this.urls, //images
        size:this.rows.value[0]['size'],//size
        color:this.rows.value[0]['color'],//color
        code:this.rows.value[0]['code'],//code
        rate:this.rows.value[0]['rate'],//rate
        estrate:this.rows.value[0]['estrate'],//estrate
        qntyshop1:this.rows.value[0]['shop1'],//shop1
        qntyshop2:this.rows.value[0]['shop2'],//shop2
        qntygodown:this.rows.value[0]['godown'],//godown
      }],

    })
      .catch((err) => {
        console.log(err);
      })
  }

  read(prod) {  
    this.prdkeyid = prod.KEYID;
    this.prdbrand = prod.brand;
    this.prdcategory = prod.category;
    this.prdhsncode = prod.hsncode;
    this.prdgst = prod.prdgst;
    this.prdigst = prod.prdigst;
    this.prdunit1 = prod.prdunit1;
    this.prdunit2 = prod.prdunit2;
  }

  
update(pid) {
  this.afs.collection("Products").doc(pid).update({
      brand: this.prdbrand,
      category: this.prdcategory,
  }).then(() => {
    console.log('updatedF');
  })
}


delete(prd) {
  this.afs.collection("Products").doc(prd.KEYID).delete().then(() => {
    console.log('deleted');
  })
}





onAddRow() {
  this.rows.push(this.createItemFormGroup());
}

onRemoveRow(rowIndex:number){
  this.rows.removeAt(rowIndex);
}

createItemFormGroup(): FormGroup {

  return this.fb.group({
    images: null,
    size: null,
    color: null,
    code: null,
    rate: null,
    estrate: null,
    shop1: null,
    shop2: null,
    godown: null,
  });
}


onEditRow(o) {
  console.log((o));
}


urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  console.log(this.urls);
}




}
