import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import 'rxjs';

import { Observable } from 'rxjs';
//export interface Item { name: string; rate: number;}
import { map } from 'rxjs/operators';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';


@Component({
  selector: 'app-productcards',
  templateUrl: './productcards.component.html',
  styleUrls: ['./productcards.component.css']
})
export class ProductcardsComponent implements OnInit {

  constructor(private afs: AngularFirestore) { }
  prcollection: any;
  qntycollection: any;
  p: number = 1;

  ngOnInit() {

    this.prcollection = this.afs.collection('Products').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
   
  }

}
