import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-productbase',
  templateUrl: './productbase.component.html',
  styleUrls: ['./productbase.component.css']
})
export class ProductbaseComponent implements OnInit {

  public pcForm: FormGroup;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder){ }

  brndcollection: any;
  ctrycollection: any;
  prcollection: any;

  prdkeyid: any;
  prdbrand: any;
  prdcategory: any;
  prdhsncode: any;
  prdgst: any;
  prdigst: any;
  prdunit1: any;
  prdunit2: any;

  discinstitute: any;
  discprime: any;
  discregular: any;
  discwholesale: any;

  ngOnInit() {

    this.Form();  

    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
    this.afs.collection('Productbase', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , this.prdcategory)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          this.prdhsncode=doc.data().hsncode;
          this.prdgst=doc.data().gst;
          this.prdigst=doc.data().igst;
          this.prdunit1=doc.data().unit1;
          this.prdunit2=doc.data().unit2;
          this.discinstitute=doc.data().discount.institute;
          this.discprime=doc.data().discount.prime;
          this.discregular=doc.data().discount.regular;
          this.discwholesale=doc.data().discount.regular;
          })
      }
      else{
        this.prdhsncode='';
        this.prdgst=0;
        this.prdigst=0;
        this.prdunit1=1;
        this.prdunit2='PC(S)';
        this.discinstitute=0;
        this.discprime=0;
        this.discregular=0;
        this.discwholesale=0;
      }
})
  }
  
  
  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      producthsncode: [''],
      productgst: [''],
      productigst: [''],
      productunit1: [''],
      productunit2: [''],
      discountinstitute: [''],
      discountprime: [''],
      discountregular: [''],
      discountwholesale: [''],
    })
  }

  // // Accessing form control using getters
  get productbrand() {
    return this.pcForm.get('productbrand');
  }

  get productcategory() {
    return this.pcForm.get('productcategory');
  }

  get producthsncode() {
    return this.pcForm.get('producthsncode');
  }

  get productgst() {
    return this.pcForm.get('productgst');
  }

  get productigst() {
    return this.pcForm.get('productigst');
  }

  get productunit1() {
    return this.pcForm.get('productunit1');
  }

  get productunit2() {
    return this.pcForm.get('productunit2');
  }

  get discountinstitute(){
    return this.pcForm.get('discountinstitute');
  }

  get discountprime(){
    return this.pcForm.get('discountprime');
  }

  get discountregular(){
    return this.pcForm.get('discountregular');
  }

  get discountwholesale(){
    return this.pcForm.get('discountwholesale');
  }
  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct,br){
    ct=this.prdcategory.match(/\D+/g).join('');
    this.afs.collection('Productbase', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , ct)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          this.prdhsncode=doc.data().hsncode;
          this.prdgst=doc.data().gst;
          this.prdigst=doc.data().igst;
          this.prdunit1=doc.data().unit1;
          this.prdunit2=doc.data().unit2;
          this.discinstitute=doc.data().discount.institute;
          this.discprime=doc.data().discount.prime;
          this.discregular=doc.data().discount.regular;
          this.discwholesale=doc.data().discount.regular;
          })
      }
      else{
        this.prdhsncode='';
        this.prdgst=0;
        this.prdigst=0;
        this.prdunit1=0;
        this.prdunit2='';
        this.discinstitute=0;
        this.discprime=0;
        this.discregular=0;
        this.discwholesale=0;
      }
})
    }


  // Reset form's values
  ResetForm() {
    this.pcForm.reset();
  }
  

  submitProductBaseData() {
    this.add(this.prdbrand,this.prdcategory);
    this.ResetForm();  // Reset form when clicked on reset button
  };


   add(br,ct) {
    // let ids =this.afs.createId();
    // this.afs.collection("Productbase").doc(ids).set({
    //   KEYID: ids,
ct=ct.match(/\D+/g).join('');
    let ids =br+ct;
    this.afs.collection("Productbase").doc(ids).set({
      KEYID: ids,
      brand: this.prdbrand,
      //category: this.prdcategory,
      category: this.prdcategory.match(/\D+/g).join(''),
     hsncode: this.prdhsncode,
      gst: Number(this.prdgst),
      igst: Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,

      discount:{
        institute: Number(this.discinstitute),
        prime: Number(this.discprime),
        regular: Number(this.discregular),
        wholesale: Number(this.discwholesale),
      }
    },{merge:true})
      .catch((err) => {
        console.log(err);
      })
  }
  
update(br,ct) {
  ct=ct.match(/\D+/g).join('');
  this.afs.collection("Productbase").doc(br+ct).update({
    hsncode: this.prdhsncode,
      gst: Number(this.prdgst),
      igst: Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,

      discount:{
        institute: Number(this.discinstitute),
        prime: Number(this.discprime),
        regular: Number(this.discregular),
        wholesale: Number(this.discwholesale),
      }
  }).then(() => {
    console.log('updatedF');
  })
  this.afs.collection("Products", ref => ref.where('brand', "==" ,br).where('category', "==" , ct)).get().toPromise().then(querySnapshot => {
    querySnapshot.forEach(doc => {
      doc.ref.update({
    hsncode: this.prdhsncode,
      gst: Number(this.prdgst),
      igst: Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,

      discount:{
        institute: Number(this.discinstitute),
        prime: Number(this.discprime),
        regular: Number(this.discregular),
        wholesale: Number(this.discwholesale),
      }
  }).then(() => {
    console.log('updatedF');
  })

})
})
}


delete(br,ct) {
  ct=ct.match(/\D+/g).join('');
  this.afs.collection("Productbase").doc(br+ct).delete().then(() => {
    console.log('deleted');
  })
  this.ResetForm();  // Reset form when clicked on reset button
}


}
