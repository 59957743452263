import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-productentry',
  templateUrl: './productentry.component.html',
  styleUrls: ['./productentry.component.css']
})
export class ProductentryComponent implements OnInit {

  addForm: FormGroup;
  rows: FormArray;
  itemForm: FormGroup;

  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder) { this.addForm = this.fb.group({
    items: [null, Validators.required],
    items_value: ['no', Validators.required]
  });

  this.rows = this.fb.array([]);
}

  brndcollection: any;
  ctrycollection: any;
  prcollection: any;
  filetot: number;

  
  ngOnInit() {

    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
   

  this.addForm.get("items").valueChanges.subscribe(val => {
    if (val === true) {
      this.addForm.get("items_value").setValue("yes");

      this.addForm.addControl('rows', this.rows);
    }
    if (val === false) {
      this.addForm.get("items_value").setValue("no");
      this.addForm.removeControl('rows');
    }
  });

//temp
  this.prcollection = this.afs.collection('Products', ref => ref.where('category', "==" , 'BADMINTON SHOE')).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
//temp

  }

  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct){
    this.prcollection = this.afs.collection('Products', ref => ref.where('category', "==" , ct)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  
    }


  onAddRow() {
    this.rows.push(this.createItemFormGroup());
  }

  onRemoveRow(rowIndex:number){
    this.rows.removeAt(rowIndex);
  }

  createItemFormGroup(): FormGroup {

    return this.fb.group({
      name: null,
      description: null,
      qty: null
    });
  }


  onEditRow(o) {
    console.log((o));
  }



  urls = new Array<string>();
  detectFiles(event) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
          console.log(this.urls);
          this.filetot = this.urls.length;

        }
        reader.readAsDataURL(file);
      }
    }
  }

  deleteImage(key){
    this.urls.splice(key,1);
    this.filetot = this.urls.length;
    console.log(this.urls);
  }

}
