  import { Component,AfterViewInit, OnInit, ViewChild } from '@angular/core';
  import { AngularFirestore } from '@angular/fire/firestore';
  
  import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatExpansionPanel} from '@angular/material';
  import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
  import { DomSanitizer } from '@angular/platform-browser';
  import 'rxjs';
  
  import { forEach,sum} from 'lodash';
  
  
  import {
    animate,
    animateChild,
    group,
    state,
    style,
    transition,
    trigger,
    query,
    AnimationTriggerMetadata,
  } from '@angular/animations';
  import { Router, ActivatedRoute } from '@angular/router';
  import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
  import { AppDateAdapter, APP_DATE_FORMATS } from '../employees/format-datepicker';
  
  import { DatePipe } from '@angular/common';
  
  import * as moment from 'moment';
  
  const EXPANSION_PANEL_ANIMATION_TIMING = '3000ms cubic-bezier(0.4,0.0,0.2,1)';
  MatExpansionPanel['decorators'][0].args[0].animations = [
    trigger('bodyExpansion', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed, void => collapsed',
        animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ])];
    
  @Component({
    selector: 'app-employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.css'],
    providers: [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
  })
  export class EmployeesComponent implements OnInit {
  
    panelOpenState = false;
  
  
    public employeeForm: FormGroup;
    filetot: number;
  
   displayedColumns = ['employeeImage', 'employeeName', 'employeeAddress', 'employeeContact', 'edit', 'delete'];
    dataSource: MatTableDataSource<any>; 
  
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
    empkeyid: any;
    empname: any;
    dojdate: any='';
    empaddress11: any='';
    empaddress12: any='';
    empaddress21: any='';
    empaddress22: any='';
    empcontact: any;
    empemail: any='';
    empgst: any='';
    ///////filter
    search: any;
    selection: any;
    
    emptypeH: string;
    employeesH: string[] = ['Mr. ', 'Mrs. ', 'Ms. ', 'M/s. '];
  
    enableUpdate: boolean = false;

    constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, public dialog: MatDialog, public fb: FormBuilder, private route: ActivatedRoute, public router: Router, public datePipe: DatePipe) {   }
  
    ngAfterViewInit() {
      this.afs.collection<any>('Employees').valueChanges().subscribe(data => {
        this.dataSource = new MatTableDataSource(data); 
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
  
  
        //It searches name and address columns. To search in entire table skip it
        this.dataSource.filterPredicate = (data, filter) =>
        (data.employeeName.indexOf(filter) !== -1);
        //----------------------------------------------------------------------
        console.log((this.dataSource));
      })
    }
  
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim().toUpperCase();
      //filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;  
    };
  
    trackByUid(index, item) {
      return item.uid;
    }
  
    
    ngOnInit() {
      this.emptypeH=this.employeesH[0]; // set default radio button checked
  
      this.Form();
    }
    
      // Reactive employee form
      Form() {
        this.employeeForm = this.fb.group({
          employeename: [null, [Validators.required,Validators.minLength(3), Validators.maxLength(30)]],
          dateofjoin: [''],
          employeeaddress11: [''],
          employeeaddress12: [''],
          employeeaddress21: [''],
          employeeaddress22: [''],
          employeecontact: [null, [Validators.required,Validators.minLength(10)]],
          employeeemail: [''],
          employeeimage: [''],
        })
      }
  
      // Accessing form control using getters
      get employeename() {
        return this.employeeForm.get('employeename').setValidators([Validators.required, Validators.minLength(3)]);
      }
  
      get dateofjoin() {
        return this.employeeForm.get('dateofjoin');
      }
  
      get employeeaddress11() {
        return this.employeeForm.get('employeeaddress11');
      }
  
      get employeeaddress12() {
        return this.employeeForm.get('employeeaddress12');
      }
  
      get employeeaddress21() {
        return this.employeeForm.get('employeeaddress21');
      }
  
      get employeeaddress22() {
        return this.employeeForm.get('employeeaddress22');
      }
  
      get employeecontact() {
        return this.employeeForm.get('employeecontact');
      }
  
      get employeeemail() {
        return this.employeeForm.get('employeeemail');
      }
  
      get employeeimage() {
        return this.employeeForm.get('employeeimage');
      }
      
      // Reset employee form's values
      ResetForm() {
        this.urls = [];
        this.filetot= 0;
        this.employeeForm.reset();
        this.enableUpdate=false;
      }
      
  
      submitEmployeeData() {
        this.add(this.employeeForm.value.employeeid);
    
        this.ResetForm();  // Reset form when clicked on reset button
    
    
      };
    
    
      // add(id) {
      //   this.afs.collection("Employees").doc("PAR"+id).set({
      //     employeeId: "Employee "+id,
      //     employeeName: this.empname,
      //     employeeAddress: this.empaddress,
      //     employeeContact: this.empcontact,
      //   })
      //     .catch((err) => {
      //       console.log(err);
      //     })
      // }
  
      add(id) {
        let ids =this.afs.createId();
        this.afs.collection("Employees").doc(ids).set({
          KEYID: ids,
          employeeName: this.empname.toUpperCase(),
          date: this.dojdate,
          date2: this.datePipe.transform(this.dojdate,"dd-MM-yyyy"),
          employeeHonor: this.emptypeH,
          employeeAddress11: this.empaddress11,
          employeeAddress12: this.empaddress12,
          employeeAddress21: this.empaddress21,
          employeeAddress22: this.empaddress22,
          employeeContact: this.empcontact.toString(),
          employeeEmail: this.empemail,
          employeeImage: this.urls, //images
          particulartype: "employee"
        })
          .catch((err) => {
            console.log(err);
          })
      }
  
      read(emp) {  
        this.empkeyid = emp.KEYID;
        this.empname = emp.employeeName;
        this.emptypeH= emp.employeeHonor,
        this.dojdate = ((moment(emp.date2, "DD-MM-YYYY").toDate()));
        console.log(((moment(emp.date2, "DD-MM-YYYY").toDate())));
        this.empaddress11 = emp.employeeAddress11;
        this.empaddress12 = emp.employeeAddress12;
        this.empaddress21 = emp.employeeAddress21;
        this.empaddress22 = emp.employeeAddress22;
        this.empcontact = emp.employeeContact;
        this.empemail = emp.employeeEmail;
        this.urls = emp.employeeImage; //images
  
             this.filetot = this.urls.length;
  
      //   this.afs.collection('Employees').doc(emp.KEYID).collection('1').get().toPromise().then(querySnapshot => {
      //     if (!querySnapshot.empty) {
      //         querySnapshot.forEach(doc => {
      //             let data = doc.data()
      //             //if (data.credit != 16) {
      //             console.log(data.credit);
      //            // }
      //         })
      //     }
      // });
  
  
    //   this.afs.collection('Employees').doc(emp.KEYID).collection('1').get().toPromise().then(querySnapshot => {
    //     if (!querySnapshot.empty) {
    //         const docSnapshots = querySnapshot.docs;
    //         for (var i in docSnapshots){
    //           const doc = docSnapshots[i].data().credit;
    //           console.log(doc);
    //         }
    //     }
    // });
  
  
    // const addcredit : string[] = [];
    //    this.afs.collection('Employees').doc(emp.KEYID).collection('/1', ref => ref.where('date', '==', '29-11-2019')).get().toPromise().then(querySnapshot => {
    //       if (!querySnapshot.empty) {
    //           querySnapshot.forEach(doc => {
    //             addcredit.push(doc.data().credit);
    //               //if (data.credit != 16) {
    //              // }
    //           })
    //           console.log(sum(addcredit));
  
    //       }
    //   });
  
  
   
      }
  
  
    update(pid) {
  
      this.afs.collection("Employees").doc(pid).update({
        employeeHonor:this.emptypeH,
        employeeName: this.empname.toUpperCase(),
        date: (this.dojdate!="Invalid Date") ? this.dojdate : null,
        date2: (this.dojdate!="Invalid Date") ? (this.datePipe.transform(this.dojdate,"dd-MM-yyyy")) : null,  
          employeeAddress11: this.empaddress11,
          employeeAddress12: this.empaddress12,
          employeeAddress21: this.empaddress21,
          employeeAddress22: this.empaddress22,
          employeeContact: this.empcontact.toString(),
          employeeEmail: this.empemail,
          employeeImage: this.urls, //images
      }).then(() => {
        console.log('updatedF');
        this.ResetForm();
      })
    }
  
    
    delete(emp) {
      this.afs.collection("Employees").doc(emp.KEYID).delete().then(() => {
        console.log('deleted');
      })
    }
  
  
  
    urls = new Array<string>();
  detectFiles(event) {
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
          console.log(this.urls);
          this.filetot = this.urls.length;
  
        }
        reader.readAsDataURL(file);
      }
    }
  }
  
  deleteImage(key){
    this.urls.splice(key,1);
    this.filetot = this.urls.length;
    console.log(this.urls);
  }
        
  }